var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full d-flex justify-space-between flex-column"},[_c('div',[_c('h2',{staticClass:"primary--text text-center mb-8"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".title")))+" ")]),_c('p',{class:{
        'text-text mb-5 px-md-10 primary--text text-center': true,
        'text-center': !_vm.kuspit_about_to_complete_view_model.small_screen,
      }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".description")))+" ")]),_c('p',{class:{
        'text-text mb-10 primary--text text-center': true,
        'text-center': !_vm.kuspit_about_to_complete_view_model.small_screen,
      }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".will_receive")))+" ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('div',_vm._l((_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".emails"))),function(email,index){return _c('div',{key:index,staticClass:"d-flex align-start"},[_c('img',{staticClass:"mr-5 mt-1",attrs:{"alt":"","src":require('@/assets/icons/email.svg'),"width":"24px"}}),_c('div',{staticClass:"w-full"},[_c('label',[_vm._v(" "+_vm._s(email.from)+" ")]),_c('p',{staticClass:"text-text dark-grey--text text-font-secondary-medium"},[_vm._v(" "+_vm._s(email.subject)+" ")])])])}),0)])]),_c('v-row',{attrs:{"align":"end"}},[_c('p',{class:{'text-text mb-5 primary--text px-md-10 pt-15 text-center': true,
        'text-center': !_vm.kuspit_about_to_complete_view_model.small_screen,}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".label")))+" ")]),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"md":"6"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"secondary","large":"","width":"100%","height":"3.5em","disabled":_vm.kuspit_about_to_complete_view_model.is_loading},on:{"click":function($event){return _vm.$emit('prevStep')}}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".back")))+" ")])],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"accent","large":"","width":"100%","height":"3.5em","disabled":_vm.kuspit_about_to_complete_view_model.is_loading},on:{"click":function($event){return _vm.kuspit_about_to_complete_view_model.createCustomerKuspitAccount()}}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.kuspit_about_to_complete_view_model.i18n_namespace) + ".continue")))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }