import Vue from 'vue';
import TYPES from '@/types';

// Application
import { CreateCustomerKuspitAccountSubscription }
  from '@/modules/my-investment/kuspit/customer-kuspit-account/application/subscriptions';
import { CreateCustomerKuspitAccountCommand }
  from '@/modules/my-investment/kuspit/customer-kuspit-account/application/commands';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import validateKuspitAccountCommand
  from '@/modules/my-investment/kuspit/kuspit-account-linking/application/commands/validate-kuspit-account-command';

// Domain
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  ValidateKuspitAccountDto,
} from '@/modules/my-investment/kuspit/kuspit-account-linking/domain/dtos/validate-kuspit-account-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';

export default class KuspitAboutToCompleteViewModel {
  @Inject(TYPES.CREATE_CUSTOMER_KUSPIT_ACCOUNT_COMMAND)
  readonly create_customer_kuspit_account_command!: CreateCustomerKuspitAccountCommand;

  @Inject(TYPES.CREATE_CUSTOMER_KUSPIT_ACCOUNT_STATUS_SUBSCRIPTION)
  readonly create_customer_kuspit_account_subscription!: CreateCustomerKuspitAccountSubscription;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_QUERY)
  private readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.VALIDATE_KUSPIT_ACCOUNT_COMMAND)
  private readonly validate_kuspit_account_command!: validateKuspitAccountCommand;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.contract-savings.about-to-complete';

  readonly customer_id = sessionStorage.getItem('user_id');

  small_screen: null | boolean = null;

  is_loading = false;

  investment_provider_id = '';

  email_address = '';

  is_a_link = false;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    this.small_screen = getScreenSizeVariant() === 'small';
    this.create_customer_kuspit_account_subscription.execute(
      this.customer_id!,
    );
    await this.setCurrentStep('kuspit');
    this.view.$emit('loadingInfo', false);
  }

  get searchOnBoardingSteps() {
    return this.search_on_boarding_steps_query.execute(this.investment_provider_id);
  }

  get investmentProviders() {
    return this.search_investment_providers_query.execute();
  }

  setInvestmentProviderId = (
    investment_providers: Array<InvestmentProviderEntity>, service: string,
  ) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === service,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  setCurrentStep = async (service: string) => {
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers, service);

    const steps = await this.searchOnBoardingSteps;

    const link_kuspit_status_step = steps.find(
      (step) => step.current_step === 'on_boarding_kuspit_status',
    );

    if (link_kuspit_status_step) {
      this.is_a_link = link_kuspit_status_step.payload.status === 'account_without_contract';
      this.email_address = link_kuspit_status_step.payload.email_address;
    }
  }

  async createCustomerKuspitAccount() {
    try {
      this.is_loading = true;

      await this.create_customer_kuspit_account_subscription.execute(this.customer_id!);

      if (this.is_a_link) {
        const validate_kuspit_account_dto: ValidateKuspitAccountDto = {
          id: this.customer_id!,
          email_address: this.email_address,
          password: '',
        };
        await this.validate_kuspit_account_command.execute(validate_kuspit_account_dto);
      } else {
        await this.create_customer_kuspit_account_command.execute(this.customer_id!);
      }
      this.view.$emit('nextStep');
      return true;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.start_process'));
      return false;
    } finally {
      this.is_loading = false;
    }
  }
}
